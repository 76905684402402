






import Vue from 'vue';
import PreferencesScaffold from '@/components/Presenter/PreferencesScaffold.vue';
export default Vue.extend({
  components: { PreferencesScaffold },
  async mounted() {
    const userId = this.$route.params.uid;
    await this.$store.dispatch('preferences/bindUserPreferences', userId);
  },
});
