






































import Vue from 'vue';
export default Vue.extend({
  name: 'PreferencesScaffold',
  data() {
    return {};
  },
});
